import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchStores } from 'state/actions/stores';
import { importIntegratorCategories, fetchIntegratorCategories, saveMappingCategories } from 'state/actions/integratorCategories';
import { fetchCategories } from 'state/actions/categories';
import { fetchMappings } from 'state/actions/mappings';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import { nestIntegratorCategories, nestCategories } from 'utils';
import Select from "react-select";
import PopupSelect from "components/PopupSelect";
import classNames from 'classnames';
import classes from './StoreCategories.module.scss';

const StoreCategories = () => {
  const { id } = useParams();
  const [importingCategories, setImportingCategories] = useState(false);
  const [savingCategories, setSavingCategories] = useState(false);
  const [isLoadedData, setIsLoadedData] = useState(false);

  const { storeData, integratorCategories, categoriesList, mappingsList, mappingsLoading, categoriesLoading, integratorCategoriesLoading } = useSelector(
    (state) => ({
      storeData: state.stores.data.find((store) => store.id === id),
      integratorCategories: state.integratorCategories.data,
      categoriesList: state.categories.data,
      mappingsList: state.mappings.data,
      mappingsLoading: state.mappings.loading,
      categoriesLoading: state.categories.loading,
      integratorCategoriesLoading: state.integratorCategories.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      if (!storeData) {
        dispatch(fetchStores(id));
      }   
    }
  }, [id, storeData, dispatch]);

  const integratorCategoriesData = storeData && storeData.integratorID ? 
    nestIntegratorCategories(integratorCategories.filter(item => item.companyId === storeData.integratorID)).map(item => {
      const prefix = item.depth && item.depth > 0 ? ('—').repeat(item.depth) : '';

      return ({...item, name: `${prefix}${item.name}`});
    })
  : 
    [];

  const categoriesData = nestCategories(categoriesList).map(item => {
    const prefix = item.prefixDepth && item.prefixDepth > 0 ? ('—').repeat(item.prefixDepth) : '';
    return ({...item, name: `${prefix}${item.name}`});
  });
  const mappingsData = mappingsList && mappingsList.length > 0 && storeData && storeData.integratorID ? mappingsList.filter(item => item.companyId === storeData.integratorID && item.type === 'category') : [];
  const defaultValuesMappings = mappingsData.filter(item => item.source !== '').sort((a,b) => a?.order - b?.order).map(item => ({ source: item.source, destination: item.destination }));
  const defaultCategory = mappingsData.find(item => item.source === '')?.destination || '';
  const existDefaultCategory = defaultCategory && !!categoriesList.find(catItem => catItem.id === defaultCategory);

  const schema = yup.object().shape({
    defaultCategories: yup.string(),
    mappings: yup.array().of(
      yup.object().shape({
        source: yup.string().required(),
        destination: yup.string().required(),
      }),
    ),
  });

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      defaultCategory,
      mappings: [...defaultValuesMappings]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappings"
  });

  useEffect(() => {
    if (id && storeData && storeData.integratorID) {
      dispatch(fetchCategories())
      .then(() => dispatch(fetchMappings({companyId: storeData.integratorID}))
        .then(() => dispatch(fetchIntegratorCategories({companyId: storeData.integratorID}))
          .then(() => setIsLoadedData(true))));           
    }
  }, []);

  useEffect(() => {
    if (!mappingsLoading && !categoriesLoading && !integratorCategoriesLoading && isLoadedData) {
      if(defaultValuesMappings && defaultValuesMappings.length > 0 || defaultCategory){
        const validateDefaultMappings = defaultValuesMappings.map(item => {
          return ({
            source: integratorCategories.find(catItem => catItem.categoryId === item.source) ? item.source : '',
            destination: categoriesList.find(catItem => catItem.id === item.destination) ? item.destination : '',
            error: !(categoriesList.find(catItem => catItem.id === item.destination) && integratorCategories.find(catItem => catItem.categoryId === item.source))
          });
        });

        reset({defaultCategory, mappings: [...validateDefaultMappings]});
      }
    }

  }, [mappingsLoading, categoriesLoading, integratorCategoriesLoading, isLoadedData]);

  const categoriesMappingMessage = useFormatMessage('StoreCategories.categoriesMapping');
  const importIntegratorCategoriesLabel = useFormatMessage('StoreCategories.importIntegratorCategories');
  const goBackMessage = useFormatMessage('StoreCategories.goBack');
  const submitLabel = useFormatMessage('StoreCategories.submit');
  const addLabel = useFormatMessage('StoreCategories.add');

  const handleImportCategories = () => {
    setImportingCategories(true);
    dispatch(importIntegratorCategories(id)).then(() => {
      reset();
      setImportingCategories(false);
    });
  };

  const handleSubmitForm = (value) => {
    const defaultCategoryValue = {
      source: "",
      destination: value.defaultCategory
    };

    const mappings = value.mappings ? [...value.mappings] : [];
    mappings.push(defaultCategoryValue);

    setSavingCategories(true);
    dispatch(saveMappingCategories(id, mappings)).then(() => {
      reset({
        mappings: value.mappings,
        defaultCategory: value.defaultCategory
      });
      setSavingCategories(false);
    });
  };

  const onMenuOpenHandler = (ref) => {
    setTimeout(() => {
      const { menuListRef } = ref.current;
      if(menuListRef){
        const currentElem = menuListRef.querySelector('[aria-selected="true"]');
        if(currentElem){
          menuListRef.scrollTop = currentElem.offsetTop;
        }
      }
    }, 50);
  };

  return (
    <>
      {storeData ?
        <>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <h1 className="title">
                {`${categoriesMappingMessage} for ${storeData.name}`} 
              </h1>
              <Link to={`${paths.STORES}/${id}`} className="button has-icon">
                <span className="icon">
                  <i className="mdi mdi-arrow-left"/>
                </span>
                {goBackMessage}
              </Link>

              <span
                onClick={handleImportCategories}
                className={`button is-inline-block ml-2 ${
                  importingCategories && 'is-loading'
                }`}
              >
                <span>{importIntegratorCategoriesLabel}</span>
              </span>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <div className="card tile is-child">
                  <div className="card-content">
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                      <h3 className="title is-6">Default Category</h3>

                      <div className={classNames("columns is-vcentered", {"has-background-danger-light":!existDefaultCategory && defaultCategory && isLoadedData})}>
                        <div className="column">
                          <div className="field is-horizontal">
                            <div className="field-body">
                              <div className="field">
                                <div className="control">
                                  <div className={classes.select}>
                                    <Controller                            
                                      name="defaultCategory"
                                      control={control}
                                      render={({ onChange, ref, value }) => (
                                        <Select
                                          isLoading={categoriesLoading}
                                          isClearable
                                          ref={ref}
                                          options={categoriesData?.map((cat) => ({value: cat.id, label: cat.name}))}
                                          onChange={val => onChange(val?.value || '')}
                                          value={categoriesData?.filter((cat) => cat.id === value).map((cat) => ({value: cat.id, label: cat.name}))}
                                          onMenuOpen={() => onMenuOpenHandler(ref)}
                                          classNames="firebase-categories"
                                          components={{ Control: PopupSelect }}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr/>
                      <h3 className="title is-6">Mappings</h3>
                      {fields.map((item, index) => (
                        <div key={item.id} className={classNames("columns is-vcentered", {"has-background-danger-light": item.error && isLoadedData})}>
                          <div className="column is-4">
                            <div className="field">
                              <div className="control">
                                <Controller                            
                                  name={`mappings[${index}].source`}
                                  control={control}
                                  defaultValue={item.source}
                                  render={({ onChange, ref, value }) => (
                                    <Select
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors?.mappings?.[index]?.source ? 'red' : baseStyles.borderColor,
                                        }),
                                      }}
                                      isLoading={integratorCategoriesLoading}
                                      isClearable
                                      ref={ref}
                                      options={integratorCategoriesData?.map((cat) => ({value: cat.categoryId, label: cat.name}))}
                                      onChange={val => onChange(val?.value || '')}
                                      value={integratorCategoriesData?.filter((cat) => cat.categoryId === value).map((cat) => ({value: cat.categoryId, label: cat.name}))}
                                      onMenuOpen={() => onMenuOpenHandler(ref)}
                                      classNames="integrator-categories"
                                      components={{ Control: PopupSelect }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="column is-4">
                            <div className="field">
                              <div className="control">
                                <Controller                            
                                  name={`mappings[${index}].destination`}
                                  control={control}
                                  defaultValue={item.destination}
                                  render={({ onChange, ref, value }) => (
                                    <Select
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors?.mappings?.[index]?.destination ? 'red' : baseStyles.borderColor,
                                        }),
                                      }}
                                      isLoading={categoriesLoading}
                                      isClearable
                                      ref={ref}
                                      options={categoriesData?.map((cat) => ({value: cat.id, label: cat.name}))}
                                      onChange={val => onChange(val?.value || '')}
                                      value={categoriesData.filter((cat) => cat.id === value).map((cat) => ({value: cat.id, label: cat.name})) || null}
                                      onMenuOpen={() => onMenuOpenHandler(ref)}
                                      classNames="firebase-categories"
                                      components={{ Control: PopupSelect }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="column is-4">
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <div className="control">
                                  <button
                                    type="button"
                                    className="button is-small is-danger"
                                    onClick={() => remove(index)}
                                  >
                                    <span className="icon is-small">
                                      <i className="mdi mdi-trash-can" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
  
                      <div className="field">
                        <button
                          disabled={savingCategories}
                          className="button"
                          type="button"
                          onClick={() => append({ source: "", destination: "", error: false })}
                        >
                          {addLabel}
                        </button>
                      </div>
                      <div className="field">
                        <button
                          disabled={savingCategories}
                          className={`button blue-button ${
                            savingCategories && 'is-loading'
                          }`}
                          type="submit" 
                        >
                          {submitLabel}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      :
        <section className="section is-main-section">Store not found</section>
      }
    </>
  );
};

export default StoreCategories;
