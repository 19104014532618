import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchStores } from 'state/actions/stores';
import { fetchIntegratorTags, importIntegratorTags } from 'state/actions/integratorTags';
import { fetchIntegratorCategories, importIntegratorCategories } from 'state/actions/integratorCategories';
import { fetchCategories } from 'state/actions/categories';
import { getDefaultMappingCategory, addDefaultMappingCategory } from 'state/actions/mappings';
import { fetchComplexMappings, saveComplexMapping } from 'state/actions/complexMappings';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import { nestIntegratorCategories, nestCategories } from 'utils';
import PopupSelect from "components/PopupSelect";
import Loader from 'components/Loader';
import Select from "react-select";
import classNames from 'classnames';
import classes from './StoreComplexMapping.module.scss';

const StoreComplexMapping = () => {
  const { id } = useParams();
  const [savingComplexMapping, setSavingComplexMapping] = useState(false);
  const [importingCategories, setImportingCategories] = useState(false);
  const [defaultCategoryObj, setDefaultCategoryObj] = useState('');
  const [importingTags, setImportingTags] = useState(false);
  const [isLoadedData, setIsLoadedData] = useState(false);

  const { storeData, integratorTags, integratorCategories, categoriesList, mappingsList, mappingsLoading, categoriesLoading, integratorTagsLoading, integratorCategoriesLoading} = useSelector(
    (state) => ({
      storeData: state.stores.data.find((store) => store.id === id),
      integratorCategories: state.integratorCategories.data,
      integratorTags: state.integratorTags.data,
      categoriesList: state.categories.data,
      mappingsList: state.complexMappings.data,
      mappingsLoading: state.complexMappings.loading,
      categoriesLoading: state.categories.loading,
      integratorTagsLoading: state.integratorTags.loading,
      integratorCategoriesLoading: state.integratorCategories.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      if (!storeData) {
        dispatch(fetchStores(id));
      }
    }

  }, [id, storeData, dispatch]);

  const integratorTagsData = storeData && storeData.integratorID ? 
    integratorTags.filter(item => item.companyId === storeData.integratorID).map(item => {
      const prefix = item.depth && item.depth > 0 ? ('—').repeat(item.depth) : '';

      return ({...item, name: `${prefix}${item.name}`});
    })
  : 
    [];

  const integratorCategoriesData = storeData && storeData.integratorID ? 
    nestIntegratorCategories(integratorCategories.filter(item => item.companyId === storeData.integratorID)).map(item => {
      const prefix = item.depth && item.depth > 0 ? ('—').repeat(item.depth) : '';

      return ({...item, name: `${prefix}${item.name}`});
    })
  : 
    [];

  const categoriesData = nestCategories(categoriesList).map(item => {
    const prefix = item.prefixDepth && item.prefixDepth > 0 ? ('—').repeat(item.prefixDepth) : '';
    return ({...item, name: `${prefix}${item.name}`});
  });
  
  const defaultValuesMappings = !mappingsLoading && mappingsList && mappingsList.length > 0 && storeData && storeData.integratorID 
  ? mappingsList.filter(item => item.companyId === storeData.integratorID).sort((a,b) => a?.order - b?.order).map(item => ({conditions: item.conditions, destinations: item.destinations, relationship: item?.relationship || 'and'})) 
  : [];
  
  const defaultCategory = defaultCategoryObj.destination || '';
  const existDefaultCategory = defaultCategory && !!categoriesList.find(catItem => catItem.id === defaultCategory);

  const schema = yup.object().shape({
    mappings: yup.array().of(
      yup.object().shape({
        conditions: yup.array().of(
          yup.object().shape({
            type: yup.string().required(),
            source: yup.string().required()
          }),
        ),
        destinations: yup.array().of(
          yup.object().shape({
            destination: yup.string().required(),
          }),
        ),
      }),
    ),
  });

  function ConditionsRelationshipValue({ control, nestIndex }) {
    const value = useWatch({
      control,
      name: "mappings",
    });

    return (value[nestIndex]?.relationship || 'and').toUpperCase();
  }

  const { control, register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    shouldUnregister: false,
    defaultValues: {
      defaultCategory,
      mappings: [...defaultValuesMappings]
    }
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "mappings"
  });

  useEffect(() => {
    if (id && storeData && storeData.integratorID) {

      dispatch(fetchCategories())
      .then(() => dispatch(fetchComplexMappings({companyId: storeData.integratorID}))
        .then(() => dispatch(fetchIntegratorTags({companyId: storeData.integratorID}))
          .then(() => dispatch(fetchIntegratorCategories({companyId: storeData.integratorID}))
            .then(() => getDefaultMappingCategory(storeData.integratorID)
              .then((result) => {
                if(result){
                  setDefaultCategoryObj(result);
                } 
                setIsLoadedData(true);
              })
            )
          )
        )
      ); 
    }
  }, []);

  useEffect(() => {
    if (!mappingsLoading && !categoriesLoading && !integratorTagsLoading && !integratorCategoriesLoading && isLoadedData) {
      if(defaultValuesMappings && defaultValuesMappings.length > 0){
        
        const validateDefaultMappings = defaultValuesMappings.map(item => {
          let error = false;
          const destinations = item?.destinations.map(destinationItem => {
            const existDestination = categoriesList.find(catItem => catItem.id === destinationItem.destination);
            if(!existDestination){
              error = true;
            }

            return ({destination: existDestination ? destinationItem.destination : ''});
          });

          const conditions = item?.conditions.map(conditionItem => {
            const validateCondition = {...conditionItem};
            
            if(validateCondition.type === 'tag'){
              const existTag = integratorTags.find(tagItem => tagItem.tagId === validateCondition.source);
              if(!existTag){
                error = true;
                validateCondition.source = '';
              }
            }
            if(validateCondition.type === 'category'){
              const existCat = integratorCategories.find(catItem => catItem.categoryId === validateCondition.source);
              if(!existCat){
                error = true;
                validateCondition.source = '';
              }
            }

            return ({...validateCondition});
          });

          const relationship = item?.relationship || 'and';

          return ({
            conditions,
            destinations,
            error,
            relationship
          });
        });

        reset({defaultCategory, mappings: [...validateDefaultMappings]});
      }
    }
  }, [mappingsLoading, categoriesLoading, integratorTagsLoading, integratorCategoriesLoading, isLoadedData]);


  const complexMappingMessage = useFormatMessage('StoreComplexMapping.complexMapping');
  const goBackMessage = useFormatMessage('StoreTags.goBack');
  const submitLabel = useFormatMessage('StoreTags.submit');
  const addConditionLabel = useFormatMessage('StoreComplexMapping.addCondition');
  const addDestinationLabel = useFormatMessage('StoreComplexMapping.addDestination');
  const addMappingLabel = useFormatMessage('StoreComplexMapping.addMapping');
  const importIntegratorCategoriesLabel = useFormatMessage('StoreCategories.importIntegratorCategories');
  const importIntegratorTagsLabel = useFormatMessage('StoreTags.importIntegratorTags');
  const relationshipAndLabel = useFormatMessage('StoreComplexMapping.relationshipAnd');
  const relationshipOrLabel = useFormatMessage('StoreComplexMapping.relationshipOr');
  const conditionsRelationshipLabel = useFormatMessage('StoreComplexMapping.conditionsRelationship');

  const groupType = [
    {
      label: 'Tag',
      value: 'tag'
    },
    {
      label: 'Category',
      value: 'category'
    },
    {
      label: 'Product name (contains)',
      value: 'product_name'
    },
    {
      label: 'Product description (contains)',
      value: 'product_description'
    },
  ];

  const handleSubmitForm = (value) => {
    const mappings = value.mappings ? [...value.mappings] : [];

    setSavingComplexMapping(true);
    dispatch(saveComplexMapping(id, mappings)).then(() => {
      dispatch(addDefaultMappingCategory(value.defaultCategory, storeData.integratorID, defaultCategoryObj?.id)).then(() => {
        reset({
          defaultCategory: value.defaultCategory,
          mappings: value.mappings,
        });

        setSavingComplexMapping(false);
      });
    });
  };

  const addNewMapping = () => {
    append({
      error: false,
      relationship: 'and',
      destinations: [{ destination: "" }] ,
      conditions: [{ type: "tag", source: ""}]
    });
  };

  const onMenuOpenHandler = (ref) => {
    setTimeout(() => {
      const { menuListRef } = ref.current;
      if(menuListRef){
        const currentElem = menuListRef.querySelector('[aria-selected="true"]');
        if(currentElem){
          menuListRef.scrollTop = currentElem.offsetTop;
        }
      }
    }, 50);
  };

  const handleImportCategories = () => {
    setImportingCategories(true);
    dispatch(importIntegratorCategories(id)).then(() => {
      reset();
      setImportingCategories(false);
    });
  };

  const handleImportTags = () => {
    setImportingTags(true);
    dispatch(importIntegratorTags(id)).then(() => {
      reset();
      setImportingTags(false);
    });
  };

  const ConditionItem = ({ nestIndex, control: controlCondition, errors: errorsCondition }) => {
    const { fields: conditions, append: appendCondition, remove: removeCondition } = useFieldArray({
      control: controlCondition,
      name: `mappings[${nestIndex}].conditions`
    });

    const output = useWatch({
      name: `mappings[${nestIndex}].conditions`,
      control: controlCondition,
      defaultValue: conditions,
    });

    return (
      <>
        {conditions.map((item, fieldIndex) => {
          return (
            <div key={item.id} className="columns is-multiline">
              <div className="column is-4">
                <div className="field">
                  <div className="control">
                    <Controller                            
                      name={`mappings[${nestIndex}].conditions[${fieldIndex}].type`}
                      control={controlCondition}
                      defaultValue={item.type}
                      render={({ onChange, ref, value }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errorsCondition?.mappings?.[nestIndex]?.conditions?.[fieldIndex]?.type ? 'red' : baseStyles.borderColor,
                            }),
                          }}
                          ref={ref}
                          options={groupType.map((typeItem) => ({...typeItem}))}
                          onChange={val => onChange(val?.value || '')}
                          value={groupType.filter((typeItem) => typeItem.value === value).map((typeItem) => ({...typeItem}))}
                        />
                      )}
                    />
                  </div>
                </div>  
              </div>

              <div className="column is-6">
                <div className="field">
                  <div className="control">
                    { output[fieldIndex]?.type === 'tag' &&
                      <Controller                            
                        name={`mappings[${nestIndex}].conditions[${fieldIndex}].source`}
                        control={controlCondition}
                        defaultValue={item.source}
                        render={({ onChange, ref, value }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errorsCondition?.mappings?.[nestIndex]?.conditions?.[fieldIndex]?.source ? 'red' : baseStyles.borderColor,
                              }),
                            }}
                            isLoading={integratorTagsLoading}
                            isClearable
                            ref={ref}
                            options={integratorTagsData?.map((cat) => ({value: cat.tagId, label: cat.name}))}
                            onChange={val => onChange(val?.value || '')}
                            value={integratorTagsData?.filter((cat) => cat.tagId === value).map((cat) => ({value: cat.tagId, label: cat.name}))}
                            onMenuOpen={() => onMenuOpenHandler(ref)}
                          />
                        )}
                      />
                    }
                    { output[fieldIndex]?.type === 'category' &&
                      <Controller                            
                        name={`mappings[${nestIndex}].conditions[${fieldIndex}].source`}
                        control={controlCondition}
                        defaultValue={item.source}
                        render={({ onChange, ref, value }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errorsCondition?.mappings?.[nestIndex]?.conditions?.[fieldIndex]?.source ? 'red' : baseStyles.borderColor,
                              }),
                            }}
                            isLoading={integratorCategoriesLoading}
                            isClearable
                            ref={ref}
                            options={integratorCategoriesData?.map((cat) => ({value: cat.categoryId, label: cat.name}))}
                            onChange={val => onChange(val?.value || '')}
                            value={integratorCategoriesData?.filter((cat) => cat.categoryId === value).map((cat) => ({value: cat.categoryId, label: cat.name}))}
                            onMenuOpen={() => onMenuOpenHandler(ref)}
                            classNames="integrator-categories"
                            components={{ Control: PopupSelect }}
                          />
                        )}
                      />
                    }
                    { (output[fieldIndex]?.type === 'product_name' || output[fieldIndex]?.type === 'product_description') &&
                      <Controller
                        as={<input />}
                        name={`mappings[${nestIndex}].conditions[${fieldIndex}].source`}
                        control={controlCondition}
                        defaultValue={item.source}
                        className={classNames('input', {
                          'is-danger': errorsCondition?.mappings?.[nestIndex]?.conditions?.[fieldIndex]?.source,
                        })}
                      />
                    }
                  </div>
                </div>  
              </div>
              <div className="column is-2">
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="control">
                      <button
                        disabled={savingComplexMapping || !isLoadedData}
                        type="button"
                        className="button is-warning"
                        onClick={() => removeCondition(fieldIndex)}
                      >
                        <span className="icon">
                          <i className="mdi mdi-trash-can" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {(conditions && conditions.length - 1 > fieldIndex) &&
                <div className="column is-12">
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <ConditionsRelationshipValue control={controlCondition} nestIndex={nestIndex}/>
                    </div>
                  </div>
                </div>
              }
            </div>
          );
        })}
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="field-body">
                <div className="control">
                  <button
                    disabled={savingComplexMapping || !isLoadedData}
                    type="button"
                    className="button"
                    onClick={() =>
                      appendCondition({
                        type: "tag",
                        source: ""
                      })
                    }
                  >
                    {addConditionLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const DestinationItem = ({ nestIndex, control: controlDestination, errors: errorsDestination }) => {
    const { fields: destinations, append: appendDestination, remove: removeDestination } = useFieldArray({
      control: controlDestination,
      name: `mappings[${nestIndex}].destinations`
    });

    return (
      <>
        {destinations.map((item, fieldIndex) => {
          return (
            <div key={item.id} className="columns">
              <div className="column is-10">
                <div className="field">
                  <div className="control">
                    <Controller                            
                      name={`mappings[${nestIndex}].destinations[${fieldIndex}].destination`}
                      control={controlDestination}
                      defaultValue={item.destination}
                      render={({ onChange, ref, value }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errorsDestination?.mappings?.[nestIndex]?.destinations?.[fieldIndex]?.destination ? 'red' : baseStyles.borderColor,
                            }),
                          }}
                          isLoading={categoriesLoading}
                          isClearable
                          ref={ref}
                          options={categoriesData?.map((cat) => ({value: cat.id, label: cat.name}))}
                          onChange={val => onChange(val?.value || '')}
                          value={categoriesData.filter((cat) => cat.id === value).map((cat) => ({value: cat.id, label: cat.name})) || null}
                          onMenuOpen={() => onMenuOpenHandler(ref)}
                          classNames="firebase-categories"
                          components={{ Control: PopupSelect }}
                        />
                      )}
                    />
                  </div>
                </div>  
              </div>

              <div className="column is-2">
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="control">
                      <button
                        disabled={savingComplexMapping || !isLoadedData}
                        type="button"
                        className="button is-warning"
                        onClick={() => removeDestination(fieldIndex)}
                      >
                        <span className="icon">
                          <i className="mdi mdi-trash-can" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="columns">
          <div className="column is-2">
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="control">
                  <button
                    disabled={savingComplexMapping || !isLoadedData}
                    type="button"
                    className="button"
                    onClick={() =>
                      appendDestination({
                        destination: ""
                      })
                    }
                  >
                    {addDestinationLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {storeData ?
        <>
          { isLoadedData ?
            <>
              <section className="hero is-hero-bar">
                <div className="hero-body">
                  <h1 className="title">
                    {`${complexMappingMessage} for ${storeData.name}`} 
                  </h1>
                  <Link to={`${paths.STORES}/${id}`} className="button has-icon">
                    <span className="icon">
                      <i className="mdi mdi-arrow-left"/>
                    </span>
                    {goBackMessage}
                  </Link>

                  <button
                    disabled={!isLoadedData || importingCategories}
                    onClick={handleImportCategories}
                    className={`button is-inline-block ml-2 ${
                      importingCategories && 'is-loading'
                    }`}
                  >
                    <span>{importIntegratorCategoriesLabel}</span>
                  </button>

                  <button
                    disabled={!isLoadedData || importingTags}
                    onClick={handleImportTags}
                    className={`button is-inline-block ml-2 ${
                      importingTags && 'is-loading'
                    }`}
                  >
                    <span>{importIntegratorTagsLabel}</span>
                  </button>

                </div>
              </section>
              <section className="section is-main-section">
                <div className="tile is-ancestor">
                  <div className="tile is-parent">
                    <div className="card tile is-child">
                      <div className="card-content">
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                          <h3 className="title is-6">Default Category</h3>

                          <div className={classNames("columns is-vcentered", {"has-background-danger-light":!existDefaultCategory && defaultCategory && isLoadedData})}>
                            <div className="column">
                              <div className="field is-horizontal">
                                <div className="field-body">
                                  <div className="field">
                                    <div className="control">
                                      <div className={classes.select}>
                                        <Controller                            
                                          name="defaultCategory"
                                          control={control}
                                          render={({ onChange, ref, value }) => (
                                            <Select
                                              isLoading={categoriesLoading}
                                              isClearable
                                              ref={ref}
                                              options={categoriesData?.map((cat) => ({value: cat.id, label: cat.name}))}
                                              onChange={val => onChange(val?.value || '')}
                                              value={categoriesData?.filter((cat) => cat.id === value).map((cat) => ({value: cat.id, label: cat.name}))}
                                              onMenuOpen={() => onMenuOpenHandler(ref)}
                                              classNames="firebase-categories"
                                              components={{ Control: PopupSelect }}
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr/>

                          <h3 className="title is-6">Mappings</h3>
                          {fields.map((item, index) => (
                            <React.Fragment key={item.id}>
                              <div className={classNames("columns", {"has-background-danger-light": item.error && isLoadedData}, {"has-background-light": (index%2 !== 0 && !(item.error && isLoadedData))})}>
                                <div className="column is-7">
                                  <ConditionItem nestIndex={index} control={control} errors={errors} />

                                  <div className="columns">
                                    <div className="column">
                                      <div className="field">
                                        <div className="field-body">
                                          <div className="control">
                                            <span className="title is-6 mr-3">{conditionsRelationshipLabel}</span>
                                            <label className="radio" htmlFor={`relationship-and-${item.id}`}>
                                              <input
                                                id={`relationship-and-${item.id}`}
                                                type="radio"
                                                ref={register()}
                                                value='and'
                                                name={`mappings[${index}].relationship`}
                                                defaultChecked={item.relationship === "and"}
                                              />
                                              {` ${relationshipAndLabel}`}
                                            </label>
                                            <label className="radio" htmlFor={`relationship-or-${item.id}`}>
                                              <input
                                                id={`relationship-or-${item.id}`}
                                                type="radio"
                                                ref={register()}
                                                value='or'
                                                name={`mappings[${index}].relationship`}
                                                defaultChecked={item.relationship === "or"}
                                              />
                                              {` ${relationshipOrLabel}`}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="column is-4">
                                  <DestinationItem nestIndex={index} control={control} errors={errors} />
                                </div>

                                <div className="column is-1">
                                  <div className="field is-horizontal">
                                    <div className="field-body">
                                      <div className="control">
                                        <button
                                          disabled={savingComplexMapping || !isLoadedData}
                                          type="button"
                                          className="button is-danger"
                                          onClick={() => remove(index)}
                                        >
                                          <span className="icon">
                                            <i className="mdi mdi-trash-can" />
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr/>
                            </React.Fragment>
                          ))}

                          <div className="field">
                            <button
                              disabled={savingComplexMapping || !isLoadedData}
                              className={`button ${
                                mappingsLoading && categoriesLoading && integratorTagsLoading && integratorCategoriesLoading && 'is-loading'
                              }`}
                              type="button"
                              onClick={addNewMapping}
                            >
                              {addMappingLabel}
                            </button>
                          </div>
                          <div className="field">
                            <button
                              disabled={savingComplexMapping || importingCategories || importingTags || !isLoadedData}
                              className={`button blue-button ${
                                savingComplexMapping && 'is-loading'
                              }`}
                              type="submit" 
                            >
                              {submitLabel}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          :
            <Loader/>
          }
        </>
      :
        <section className="section is-main-section">Store not found</section>
      }
    </>
  );
};

export default StoreComplexMapping;
