import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchIntegratorSizes } from 'state/actions/integratorSizes';
import { fetchSettings, modifySetting } from 'state/actions/settings';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import Select from "react-select";
import classNames from 'classnames';
import classes from './SizesGrouping.module.scss';

const SizedGrouping = () => {
  const { integratorSizes, integratorSizesLoading, settingsData, settingsLoading } = useSelector(
    (state) => ({
      integratorSizes: state.integratorSizes.data,
      integratorSizesLoading: state.integratorSizes.loading,
      settingsData: state.settings.data,
      settingsLoading: state.settings.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [isLoadedData, setIsLoadedData] = useState(false);
  const [savingSizes, setSavingSizes] = useState(false);

  useEffect(() => {
    dispatch(fetchIntegratorSizes())
    .then(() => dispatch(fetchSettings())
      .then(() => setIsLoadedData(true))
    );
  }, []);

  const defaultValues = settingsData.find(setting => setting.id === 'sizes')?.value || [];
  let otherSizes = [...integratorSizes];

  if(defaultValues && defaultValues.length > 0 ){
    let selectedSizes = [];
    defaultValues.forEach(item => {
      selectedSizes = selectedSizes.concat(item.sizes);
    });

    otherSizes = integratorSizes.filter(item => !selectedSizes.includes(item.id));
  }

  const schema = yup.object().shape({
    groups: yup.array().of(
      yup.object().shape({
        title: yup.string().required(),
        sizes: yup.array().min(1).of(yup.string().required())
      }),
    ),
  });

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "groups"
  });

  useEffect(() => {
    if (!integratorSizesLoading && !settingsLoading && isLoadedData) {
      if(defaultValues && defaultValues.length > 0){
        reset({groups:defaultValues});
      }
    }
  }, [integratorSizesLoading, settingsLoading, isLoadedData]);


  const SizesGroupingTitle = useFormatMessage('SizesGrouping.title');
  const titleField = useFormatMessage('SizesGrouping.titleField');
  const goBackLabel = useFormatMessage('goBack');
  const submitLabel = useFormatMessage('submit');
  const addLabel = useFormatMessage('SizesGrouping.addGroup');

  const handleSubmitForm = (values) => {
    const value = values.groups ? [...values.groups] : [];
    setSavingSizes(true);

    dispatch(modifySetting({value, id: 'sizes'})).then(() => {
      reset({groups: value});
      setSavingSizes(false);
    });
  };

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {SizesGroupingTitle} 
          </h1>
          <Link to={`${paths.SETTINGS}`} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackLabel}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  {fields.map((item, index) => (
                    <Fragment key={item.id}>
                      <div className={classNames("columns")}>

                        <div className="column is-2">
                          <div className="field">
                            <div className="control">
                              <Controller
                                as={<input />}
                                name={`groups[${index}].title`}
                                control={control}
                                placeholder={titleField}
                                defaultValue={item.title}
                                className={classNames('input', {
                                  'is-danger': errors?.groups?.[index]?.title,
                                })}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="column is-9">
                          <div className="field">
                            <div className="control">
                              <Controller
                                name={`groups[${index}].sizes`}
                                control={control}
                                defaultValue={item.sizes}
                                render={({ onChange, ref, value }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors?.groups?.[index]?.sizes ? 'red' : baseStyles.borderColor,
                                      }),
                                    }}
                                    isLoading={integratorSizesLoading}
                                    isClearable
                                    isMulti
                                    ref={ref}
                                    options={integratorSizes?.map((size) => ({value: size.id, label: size.name}))}
                                    onChange={values => onChange(values.map((val => (val?.value || ''))))}
                                    value={integratorSizes?.filter((size) => value.includes(size.id)).map((size) => ({value: size.id, label: size.name}))}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="column is-1">
                          <div className="field is-horizontal mt-1">
                            <div className="field-body">
                              <div className="control">
                                <button
                                  disabled={savingSizes || !isLoadedData}
                                  type="button"
                                  className="button is-small is-danger"
                                  onClick={() => remove(index)}
                                >
                                  <span className="icon is-small">
                                    <i className="mdi mdi-trash-can" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr/>
                    </Fragment>
                  ))}

                  <div className="field mb-5">
                    <button
                      disabled={savingSizes || !isLoadedData}
                      className="button"
                      type="button"
                      onClick={() => append({ title: "", sizes: "" })}
                    >
                      {addLabel}
                    </button>
                  </div>
                  <div className={classNames(classes.otherSizesContainer, "columns")}>
                    <div className="column is-1">
                      <div><p>Aðrir</p></div>
                    </div>
                    <div className="column is-11">
                      <div className={classNames(classes.otherSizes)}>
                        {otherSizes.map(size => (<span key={size.id}>{size.name}</span>))}
                      </div>
                    </div>
                  </div>

                  <hr/>

                  <div className="field">
                    <button
                      disabled={savingSizes || !isLoadedData}
                      className="button blue-button"
                      type="submit"
                    >
                      {submitLabel}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SizedGrouping;
