/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import ROLES from 'permissions/roles';
import classNames from 'classnames';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import ProgressBar from 'components/ProgressBar';
import classes from './OrderCard.module.scss';

const OrderCard = (props) => {
  const {
    style,
    onClick,
    className,
    id,
    externalId,
    content,
    date,
    pickedDateTime,
    deliveredDateTime,
    processing,
    note
  } = props;

  const {
    REACT_APP_DEADLINE_DAYS_STORE,
    REACT_APP_DEADLINE_DAYS_ORDER
  } = process.env;

  const { role } = useSelector(
    (state) => ({
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const deadLineStore = moment(date).add(REACT_APP_DEADLINE_DAYS_STORE, 'day');
  let currentPoint = moment();
  if(pickedDateTime)
    currentPoint = moment(pickedDateTime);

  const diffStore = moment.duration(deadLineStore.diff(currentPoint)).as('hours');
  let percentageForStore = 100;
  if(diffStore > 0){
    percentageForStore = Math.ceil(100 - (diffStore / (REACT_APP_DEADLINE_DAYS_STORE * 24)) * 100);
  }
  
  const deadLineOrder = moment(date).add(REACT_APP_DEADLINE_DAYS_ORDER, 'day');
  currentPoint = moment();
  if(deliveredDateTime)
    currentPoint = moment(deliveredDateTime);

  const diffOrder = moment.duration(deadLineOrder.diff(currentPoint)).as('hours');
  let percentageForOrder = 100;
  if(diffOrder > 0){
    percentageForOrder = Math.ceil(100 - (diffOrder / (REACT_APP_DEADLINE_DAYS_ORDER * 24)) * 100);
  }

  return (
    <div
        data-id={id}
        onClick={onClick}
        style={style}
        className={classNames(className, classes.orderItem)}
      >   
        <div className={classNames('box', classes.orderInner)}>
          { processing && 
            <div className={classNames(classes.loader, 'is-flex is-justify-content-center is-align-items-center')}>
              <ClipLoader />
            </div>
          }
          <header className="subtitle is-6">
            {externalId}
            {note &&
              <span className="icon">
                <i className="mdi mdi-email-outline default" />
              </span>
            }
          </header>
          <div className={classes.orderContent}>
            <p className="subtitle is-6">{ content }</p>
            <div className={classNames('is-flex is-justify-content-space-between is-align-items-center', classes.orderDate)}>
              <div className={classNames('is-flex', classes.orderDeadLine)}>
                <ProgressBar percentage={percentageForStore} />
              </div>
              { role !== ROLES.STORE &&
                <div className={classNames('is-flex', classes.orderDeadLine)}>
                  <ProgressBar percentage={percentageForOrder} />
                </div>
              }
              <p className="subtitle is-6">{ moment(date).utc().format("DD.MM.YYYY - HH:mm") }</p>
            </div>
          </div>
        </div>
      </div>
  );
};


OrderCard.propTypes = {
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  content: PropTypes.string,
  date: PropTypes.string,
  pickedDateTime: PropTypes.string,
  deliveredDateTime: PropTypes.string,
  note: PropTypes.string,
  processing: PropTypes.bool,
};

OrderCard.defaultProps = {
  onClick: () => {},
  style: {},
  className: '',
  content: '',
  date: '',
  pickedDateTime: '',
  deliveredDateTime: '',
  processing: false,
  note: ''
};

export default OrderCard;
