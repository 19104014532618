import React from 'react';
import { useFormatMessage } from 'hooks';
import { NumericFormat } from 'react-number-format';
import { ORDER_ITEM_STATUS } from 'utils';
import classNames from 'classnames';
import classes from './OrderItems.module.scss';

const OrderItems = ({ items }) => {
  return (
    <table className="table is-hoverable">
      <thead>
        <tr>
          <th> </th>
          <th>{useFormatMessage('OrderForm.SKU')}</th>
          <th>{useFormatMessage('OrderForm.Name')}</th>
          <th>{useFormatMessage('OrderForm.Qty')}</th>
          <th>{useFormatMessage('OrderForm.UnitPrice')}</th>
          <th>{useFormatMessage('OrderForm.DiscountAmount')}</th>
          <th>{useFormatMessage('OrderForm.Total')}</th>
          <th>{useFormatMessage('OrderForm.status')}</th>
        </tr>
      </thead>
      {Object.keys(items).length > 0 &&
        <tbody>
          {Object.keys(items).map(store => {
            return (
              <React.Fragment key={store}>
                <tr className={classNames(classes.withoutBg)}>
                  <th>{store}</th>
                </tr>
                {items[store].map(el => (
                  <tr key={el.SKU}>
                    <td> </td>
                    <td>{el.SKU}</td>
                    <td>{el.Name}</td>
                    <td>{el.Qty}</td>
                    <td><NumericFormat value={ el.RegularPrice } displayType='text' thousandSeparator suffix='kr' /></td>
                    <td>
                      {el.Discounts.length > 0 ?
                        <NumericFormat value={ el.Discounts[0].Value } displayType='text' thousandSeparator suffix='kr' />
                        :
                        <span>0 kr</span>
                      }
                    </td>
                    <td>
                      <NumericFormat value={ el.UnitPrice * el.Qty } displayType='text' thousandSeparator suffix='kr' />
                    </td>
                    <td>
                      <span className="select">
                        <select
                          value={el.DeliveryStatus}
                          disabled
                        >
                          {Object.keys(ORDER_ITEM_STATUS).map((status) => (
                            <option key={status} value={status}>
                              {ORDER_ITEM_STATUS[status]}
                            </option>
                          ))}
                        </select>
                      </span>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            );
          })}
        </tbody>
      }
    </table>
  );
};

export default OrderItems;
