import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkAccess } from 'permissions';

import Layout from 'components/Layout';

const PrivateRoute = ({ path, component: Component, rule = null }) => {

  const {access, redirect} = checkAccess(rule, true);

  return (
    <Layout>
      <Route
        exact
        path={path}
        render={() => (access ? <Component /> : <Redirect to={ redirect } />)}
      />
    </Layout>
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired
};

export default PrivateRoute;
