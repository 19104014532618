import React, { useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import paths from 'pages/Router/paths';
import OrderForm from 'components/OrderForm';
import { modifyOrder, fetchOrders } from 'state/actions/orders';
import { useFormatMessage } from 'hooks';

const Order = () => {
  const { id } = useParams();

  const { orderData } = useSelector(
    (state) => ({
      orderData: state.orders.data.find((order) => order.id === id),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!orderData) 
      dispatch(fetchOrders(id));
  }, [id, orderData, dispatch]);

  const editOrderMessage = useFormatMessage('Order.editOrder');
  const goBackMessage = useFormatMessage('OrderForm.goBack');
  const orderTitle = useFormatMessage('Order.titleOrder');

  const onSubmitHandler = (value) => {
    const newOrder = {
      ...orderData,
      id,
    };

    const newStatus = value.ServiceData.Source.Status;
    newOrder.ServiceData.Source.Status = newStatus;

    const newDeliveryStatus = value.ServiceData.Source.DeliveryStatus;
    newOrder.ServiceData.Source.DeliveryStatus = newDeliveryStatus;

    dispatch(modifyOrder(newOrder));
  };

  return (
    <Fragment key={id}>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          {orderData &&
            <>
              <h1 className="title">
                {`${ editOrderMessage } - ${ orderData.ServiceData.Source.Identity }`}
              </h1>
              <span className="title-for-print is-hidden">{`${ orderTitle } - ${ orderData.ServiceData.Source.Identity }`}</span>
            </>
          }
          <Link to={paths.ORDERS} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackMessage}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        { !orderData ? (
          <Loader />
        ) : (
          <OrderForm
            order={ orderData }
            onSubmitHandler={onSubmitHandler}
          />
        )}
      </section>
    </Fragment>
  );
};

export default Order;
