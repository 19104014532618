import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import { nestCategories } from 'utils';
import moment from 'moment';
import Table from 'components/Table';
import { fetchCategories, deleteCategory, categoriesCleanUp } from 'state/actions/categories';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';

const Categories = () => {
  const { categoriesList, loading, deleted } = useSelector(
    (state) => ({
      categoriesList: state.categories.data,
      loading: state.categories.loading,
      deleted: state.categories.deleted,
    }),
    shallowEqual
  );

  const categoriesData = nestCategories(categoriesList);

  const [deleteModal, setDeleteModal] = useState({
    categoryName: null,
    categoryId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchCategories());

    return () => dispatch(categoriesCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        categoryName: null,
        categoryId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);


  const onRemoveButtonClickHandler = (categoryId, categoryName) => {
    setDeleteModal((prevState) => ({
      categoryName,
      categoryId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ categoryName: null, categoryId: null, isOpen: false });
  };

  const onDeleteCategoryHandler = () => {
    dispatch(deleteCategory(deleteModal.categoryId));
  };

  const columns = [
    {
      Header: useFormatMessage('Categories.name'),
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row }) => {
        const prefix = row.original.prefixDepth && row.original.prefixDepth > 0 ? ('—').repeat(row.original.prefixDepth) : '';

        return (
          <>
            {checkAccess(`edit category`) ? (
              <span>
                {prefix}
                <Link to={`/categories/${row.original.id}`}>
                  {row.original.name}
                </Link>
              </span>
            )
            :
              prefix + row.original.name
            }
          </>
        );
      }
    },
    {
      Header: useFormatMessage('Categories.created'),
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: ({ row }) => (
        <>
          {moment(new Date(row.original.createdAt)).format("DD.MM.YYYY")}
        </>
      ),
    },
    {
      Header: useFormatMessage('Categories.showOnMenu'),
      accessor: 'showOnMenu',
      disableSortBy: true,
      Cell: ({ row }) => (
        <>
          {row.original.showOnMenu && 'yes'}
        </>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          {checkAccess(`delete category`) && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id, row.original.name)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? categoriesData.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;

        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase())
        );
      })
    : categoriesData;

  const deleteMessage = useFormatMessage('Categories.delete');

  const confirmMessage = useFormatMessage('Categories.confirm');

  const permDeleteMessage = useFormatMessage('Categories.permDelete', {categoryName: deleteModal.categoryName});

  const cancelMessage = useFormatMessage('Categories.cancel');

  const accessToAddCategory = checkAccess('create category');

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCategoryHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {accessToAddCategory && (
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">{useFormatMessage('Categories.categories')}</h1>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_CATEGORY} className="button">
                    {useFormatMessage('Categories.newCategory')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
              <div className="field is-horizontal mb-0 p-3">
                <div className="field-label is-normal">
                  <label htmlFor='inputSearch' className="label">{useFormatMessage('Categories.search')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        id="inputSearch"
                        type="text"
                        className="input"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="b-table">
            {loading ? <Loader /> : <Table columns={columns} data={data} />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Categories;
