import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchStores } from 'state/actions/stores';
import { importIntegratorTags, fetchIntegratorTags, saveMappingTags } from 'state/actions/integratorTags';
import { fetchCategories } from 'state/actions/categories';
import { fetchMappings } from 'state/actions/mappings';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import { nestCategories } from 'utils';
import Select from "react-select";
import classNames from 'classnames';
import PopupSelect from "components/PopupSelect";

const StoreTags = () => {
  const { id } = useParams();
  const [importingTags, setImportingTags] = useState(false);
  const [savingTags, setSavingTags] = useState(false);
  const [isLoadedData, setIsLoadedData] = useState(false);

  const { storeData, integratorTags, categoriesList, mappingsList, mappingsLoading, categoriesLoading, integratorTagsLoading} = useSelector(
    (state) => ({
      storeData: state.stores.data.find((store) => store.id === id),
      integratorTags: state.integratorTags.data,
      categoriesList: state.categories.data,
      mappingsList: state.mappings.data,
      mappingsLoading: state.mappings.loading,
      categoriesLoading: state.categories.loading,
      integratorTagsLoading: state.integratorTags.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      if (!storeData) {
        dispatch(fetchStores(id));
      }
    }

  }, [id, storeData, dispatch]);

  const integratorTagsData = storeData && storeData.integratorID ? 
    integratorTags.filter(item => item.companyId === storeData.integratorID).map(item => {
      const prefix = item.depth && item.depth > 0 ? ('—').repeat(item.depth) : '';

      return ({...item, name: `${prefix}${item.name}`});
    })
  : 
    [];

  const categoriesData = nestCategories(categoriesList).map(item => {
    const prefix = item.prefixDepth && item.prefixDepth > 0 ? ('—').repeat(item.prefixDepth) : '';
    return ({...item, name: `${prefix}${item.name}`});
  });
  const mappingsData = mappingsList && mappingsList.length > 0 && storeData && storeData.integratorID ? mappingsList.filter(item => item.companyId === storeData.integratorID && item.type === 'tag') : [];
  const defaultValuesMappings = mappingsData.filter(item => item.source !== '').sort((a,b) => a?.order - b?.order).map(item => ({ source: item.source, destination: item.destination }));

  const schema = yup.object().shape({
    mappings: yup.array().of(
      yup.object().shape({
        source: yup.string().required(),
        destination: yup.string().required(),
      }),
    ),
  });

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mappings: [...defaultValuesMappings]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappings"
  });

  useEffect(() => {
    if (id && storeData && storeData.integratorID) {
      dispatch(fetchCategories())
        .then(() => dispatch(fetchMappings({companyId: storeData.integratorID}))
          .then(() => dispatch(fetchIntegratorTags({companyId: storeData.integratorID}))
            .then(() => setIsLoadedData(true))));           
    }
  }, []);

  useEffect(() => {
    if (!mappingsLoading && !categoriesLoading && !integratorTagsLoading && isLoadedData) {
      if(defaultValuesMappings && defaultValuesMappings.length > 0){
        const validateDefaultMappings = defaultValuesMappings.map(item => {
          return ({
            source: integratorTags.find(tagItem => tagItem.tagId === item.source) ? item.source : '',
            destination: categoriesList.find(catItem => catItem.id === item.destination) ? item.destination : '',
            error: !(categoriesList.find(catItem => catItem.id === item.destination) && integratorTags.find(tagItem => tagItem.tagId === item.source))
          });
        });

        reset({mappings: [...validateDefaultMappings]});
      }
    }

  }, [mappingsLoading, categoriesLoading, integratorTagsLoading, isLoadedData]);

  const tagsMappingMessage = useFormatMessage('StoreTags.tagsMapping');
  const importIntegratorTagsLabel = useFormatMessage('StoreTags.importIntegratorTags');
  const goBackMessage = useFormatMessage('StoreTags.goBack');
  const submitLabel = useFormatMessage('StoreTags.submit');
  const addLabel = useFormatMessage('StoreTags.add');

  const handleImportTags = () => {
    setImportingTags(true);
    dispatch(importIntegratorTags(id)).then(() => {
      reset();
      setImportingTags(false);
    });
  };

  const handleSubmitForm = (value) => {
    const mappings = value.mappings ? [...value.mappings] : [];
    setSavingTags(true);
    dispatch(saveMappingTags(id, mappings)).then(() => {
      reset({
        mappings: value.mappings,
      });

      setSavingTags(false);
    });
  };

  const onMenuOpenHandler = (ref) => {
    setTimeout(() => {
      const { menuListRef } = ref.current;
      if(menuListRef){
        const currentElem = menuListRef.querySelector('[aria-selected="true"]');
        if(currentElem){
          menuListRef.scrollTop = currentElem.offsetTop;
        }
      }
    }, 50);
  };

  return (
    <>
      {storeData ?
        <>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <h1 className="title">
                {`${tagsMappingMessage} for ${storeData.name}`} 
              </h1>
              <Link to={`${paths.STORES}/${id}`} className="button has-icon">
                <span className="icon">
                  <i className="mdi mdi-arrow-left"/>
                </span>
                {goBackMessage}
              </Link>

              <span
                onClick={handleImportTags}
                className={`button is-inline-block ml-2 ${
                  importingTags && 'is-loading'
                }`}
              >
                <span>{importIntegratorTagsLabel}</span>
              </span>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <div className="card tile is-child">
                  <div className="card-content">
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                      <h3 className="title is-6">Mappings</h3>
                      {fields.map((item, index) => (
                        <div key={item.id} className={classNames("columns is-vcentered", {"has-background-danger-light": item.error && isLoadedData})}>
                          <div className="column is-4">
                            <div className="field">
                              <div className="control">
                                <Controller                            
                                  name={`mappings[${index}].source`}
                                  control={control}
                                  defaultValue={item.source}
                                  render={({ onChange, ref, value }) => (
                                    <Select
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors?.mappings?.[index]?.source ? 'red' : baseStyles.borderColor,
                                        }),
                                      }}
                                      isLoading={integratorTagsLoading}
                                      isClearable
                                      ref={ref}
                                      options={integratorTagsData?.map((cat) => ({value: cat.tagId, label: cat.name}))}
                                      onChange={val => onChange(val?.value || '')}
                                      value={integratorTagsData?.filter((cat) => cat.tagId === value).map((cat) => ({value: cat.tagId, label: cat.name}))}
                                      onMenuOpen={() => onMenuOpenHandler(ref)}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="column is-4">
                            <div className="field">
                              <div className="control">
                                <Controller                            
                                  name={`mappings[${index}].destination`}
                                  control={control}
                                  defaultValue={item.destination}
                                  render={({ onChange, ref, value }) => (
                                    <Select
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors?.mappings?.[index]?.destination ? 'red' : baseStyles.borderColor,
                                        }),
                                      }}
                                      isLoading={categoriesLoading}
                                      isClearable
                                      ref={ref}
                                      options={categoriesData?.map((cat) => ({value: cat.id, label: cat.name}))}
                                      onChange={val => onChange(val?.value || '')}
                                      value={categoriesData.filter((cat) => cat.id === value).map((cat) => ({value: cat.id, label: cat.name})) || null}
                                      onMenuOpen={() => onMenuOpenHandler(ref)}
                                      classNames="firebase-categories"
                                      components={{ Control: PopupSelect }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="column is-4">
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <div className="control">
                                  <button
                                    type="button"
                                    className="button is-small is-danger"
                                    onClick={() => remove(index)}
                                  >
                                    <span className="icon is-small">
                                      <i className="mdi mdi-trash-can" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="field">
                        <button
                          disabled={savingTags}
                          className="button"
                          type="button"
                          onClick={() => append({ source: "", destination: "", error: false })}
                        >
                          {addLabel}
                        </button>
                      </div>
                      <div className="field">
                        <button
                          disabled={savingTags}
                          className={`button blue-button ${
                            savingTags && 'is-loading'
                          }`}
                          type="submit" 
                        >
                          {submitLabel}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      :
        <section className="section is-main-section">Store not found</section>
      }
    </>
  );
};

export default StoreTags;
