import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ROLES from 'permissions/roles';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import TableNew from 'components/TableNew';
import { getUserList, removeUser, usersCleanUp } from 'state/actions/users';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';

const Users = () => {
  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const [search, setSearch] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [additionalFilter, setAdditionalFilter] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersCleanUp);
  }, [dispatch]);

  const applyFilters = (params) => {
    let queryOptions = null;

    const {role = null, searchValue = null} = params;

    const roleQueryOptions = [];
    const searchQueryOptions = [];

    if(role){
      roleQueryOptions.push({
        'attribute': 'role',
        'operator': '==',
        'value': role
      });
    }

    if(searchValue && searchValue.length >= 3){
      searchQueryOptions.push(
        [{
          'attribute': 'name',
          'operator': '>=',
          'value': searchValue
        },
        {
          'attribute': 'name',
          'operator': '<=',
          'value': `${searchValue}~`
        }]
      );

      searchQueryOptions.push(
        [{
          'attribute': 'email',
          'operator': '>=',
          'value': searchValue
        },
        {
          'attribute': 'email',
          'operator': '<=',
          'value': `${searchValue}~`
        }]
      );
    }

    if(searchQueryOptions.length > 0){
      searchQueryOptions.forEach((el, index) => {
        if(roleQueryOptions.length > 0){
          searchQueryOptions[index] = el.concat(roleQueryOptions);
        }
      });

      queryOptions = {
        queries: [],
        relationship: 'or'
      };

      searchQueryOptions.forEach(el => {
        queryOptions.queries.push({ queries: el, relationship: 'and' });
      });
    }
    else if(roleQueryOptions.length > 0){
      queryOptions = {
        queries: [],
        relationship: 'and'
      };

      roleQueryOptions.forEach(el => {
        queryOptions.queries.push({ queries: [el], relationship: 'and' });
      });
    }

    setAdditionalFilter(queryOptions);
  };

  const searchHandle = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);

    applyFilters({searchValue, role: roleFilter}); 
  };

  const onChangeRole = (e) => {
    const role = e.target.value;
    setRoleFilter(role);

    applyFilters({searchValue: search, role});
  };

  const deleted = false;
  const loading = false;

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);


  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDeleteUserHandler = async() => {
    setDeleting(true);

    await removeUser(deleteModal.userId);
    applyFilters({searchValue: search, role: roleFilter});
    setDeleteModal({ userId: null, isOpen: false });
    setDeleting(false);
  };

  const columnsStructure = [
    {
      accessorFn: (row) => row.logoUrl,
      id: "user.logoUrl",
      header: '',
      cell: (info) => (
        <div className="image">
          <img src={info.getValue()} alt="" className="is-rounded" />
        </div>
      )
    },
    {
      accessorFn: (row) => row.name,
      id: "user.name",
      header: useFormatMessage('Users.name'),
      cell: ({ row }) => (
        <>
          {checkAccess(`edit ${row.original.role} user`) ? (
            <Link to={`/users/${row.original.id}`}>
              {row.original.name}
            </Link>
          )
          :
            row.original.name
          }
        </>
      )
    },
    {
      accessorFn: (row) => row.email,
      id: "user.email",
      header: useFormatMessage('Users.email'),
      cell: (info) => info.getValue() || null,
    },
    {
      accessorFn: (row) => row.location,
      id: "user.location",
      header: useFormatMessage('Users.location'),
      cell: (info) => info.getValue() || null,
    },
    {
      accessorFn: (row) => row.role,
      id: "user.role",
      header: useFormatMessage('Users.role'),
      cell: ({ row }) => (row.original.isAdmin ? 'Admin' : row.original.role)
    },
    {
      accessorFn: (row) => row.createdAt,
      id: "user.createdAt",
      header: useFormatMessage('Users.created'),
      cell: (info) => {
        const value = info.getValue();

        return value ? moment(new Date(value)).format("DD.MM.YYYY") : null;
      }
    },
    {
      accessorFn: (row) => row.id,
      id: "user.actions",
      header: '',
      cell: ({ row }) => (
        <div className="buttons is-right">
          {checkAccess(`delete ${row.original.role} user`) && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      )
    },
  ];

  const deleteMessage = useFormatMessage('Users.delete');

  const confirmMessage = useFormatMessage('Users.confirm');

  const permDeleteMessage = useFormatMessage('Users.permDelete');

  const cancelMessage = useFormatMessage('Users.cancel');

  const accessToAddUser = checkAccess('create user');

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={deleting}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {accessToAddUser && (
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">{useFormatMessage('Users.users')}</h1>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_USER} className="button">
                    {useFormatMessage('Users.newUser')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
              <div className="field is-horizontal mb-0 p-3">
                <div className="field-label is-normal">
                  <label htmlFor='inputSearch' className="label">{useFormatMessage('Users.search')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        id="inputSearch"
                        type="text"
                        className="input"
                        value={search}
                        onChange={searchHandle}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal mb-0 p-3">
                <div className="field-label is-normal">
                  <label htmlFor='inputSearch' className="label">{useFormatMessage('Users.role')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select">
                        <select 
                          name="role"
                          onChange={onChangeRole}
                        >
                          <option key='all' value="">-All-</option>
                          { Object.values(ROLES).map((role) => (
                            <option key={role} value={role}>
                              {role}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <TableNew 
            columnsStructure={columnsStructure} 
            fetchDataFn={getUserList}
            additionalFilter={additionalFilter}
          />
        </div>
      </section>
    </>
  );
};

export default Users;
