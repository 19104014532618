import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Loader from 'components/Loader';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import Table from 'components/Table';
import { fetchStores, storesCleanUp, deleteStore } from 'state/actions/stores';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Stores.module.scss';

const Stores = () => {
  const { storesList, isAdmin, error, loading, deleted, users} = useSelector(
    (state) => ({
      users: state.users.data,
      storesList: state.stores.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.stores.error,
      loading: state.stores.loading,
      deleted: state.stores.deleted,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState({
    couponId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchStores());

    return () => dispatch(storesCleanUp());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        storeId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (storeId) => {
    const userStore = users.find(item => item?.additionalData?.stores?.includes(storeId));

    if(userStore){
      toastr.error('', 'Unable  to delete this store. Please detach all store users from this store.');
    }
    else{
      setDeleteModal((prevState) => ({
        storeId,
        isOpen: !prevState.isOpen,
      }));
    }
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ storeId: null, isOpen: false });
  };

  const onDeleteStoreHandler = () => {
    dispatch(deleteStore(deleteModal.storeId));
  };

  const columns = [
    {
      Header: useFormatMessage('Stores.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <>
          { checkAccess('view store') ? (
            <Link to={`/stores/${row.original.id}`}>
              { row.original.name }
            </Link>
            ) : row.original.name 
          }
        </>
      )
    },
    {
      Header: useFormatMessage('Stores.createdDate'),
      accessor: 'createdAt',
      sortType: (a, b) => {
        return new Date(b.values.createdAt) - new Date(a.values.createdAt);
      },
      Cell: ({ row }) => (
        <>
          {moment(new Date(row.original.createdAt)).format("DD.MM.YYYY")}
        </>
      ),
    },
  ];

  const actionCell = {
    Header: 'Actions',
    id: 'actions',
    accessor: 'id',
    Cell: ({ row }) => (
      <>
        <div className="buttons is-right">
          <Link
            to={`/stores/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-pencil" />
            </span>
          </Link>
          
          {checkAccess(`delete store`) && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      </>
    ),
    disableSortBy: true,
  };

  if (checkAccess('view store')) columns.push(actionCell);

  const accessToAddStore = checkAccess('create store');

  const deleteMessage = useFormatMessage('Stores.delete');
  const confirmMessage = useFormatMessage('Stores.confirm');
  const permDeleteMessage = useFormatMessage('Stores.permDelete');
  const cancelMessage = useFormatMessage('Stores.cancel');

  const data = search
    ? storesList.filter((el) => {
        const clonedElem = { name: el.name };
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase())
        );
      })
    : storesList;

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteStoreHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Stores.stores')}</h1>
              </div>
            </div>
            {accessToAddStore &&
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_STORE} className="button">
                    {useFormatMessage('Stores.newStore')}
                  </Link>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Stores.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <Loader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Stores;
