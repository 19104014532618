import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import firebase from 'firebase.js';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const STORES_FETCH_DATA_INIT = createAction('STORES_FETCH_DATA_INIT');
export const STORES_FETCH_DATA_SUCCESS = createAction(
  'STORES_FETCH_DATA_SUCCESS'
);
export const STORES_FETCH_DATA_FAIL = createAction('STORES_FETCH_DATA_FAIL');

export const STORES_DELETE_STORE_INIT = createAction('STORES_DELETE_STORE_INIT');
export const STORES_DELETE_STORE_SUCCESS = createAction(
  'STORES_DELETE_STORE_SUCCESS'
);
export const STORES_DELETE_STORE_FAIL = createAction('STORES_DELETE_STORE_FAIL');

export const STORES_CREATE_STORE_INIT = createAction('STORES_CREATE_STORE_INIT');
export const STORES_CREATE_STORE_SUCCESS = createAction(
  'STORES_CREATE_STORE_SUCCESS'
);
export const STORES_CREATE_STORE_FAIL = createAction('STORES_CREATE_STORE_FAIL');

export const STORES_MODIFY_STORE_INIT = createAction('STORES_MODIFY_STORE_INIT');
export const STORES_MODIFY_STORE_SUCCESS = createAction(
  'STORES_MODIFY_STORE_SUCCESS'
);
export const STORES_MODIFY_STORE_FAIL = createAction('STORES_MODIFY_STORE_FAIL');

export const STORES_CLEAN_UP = createAction('STORES_CLEAN_UP');

export const STORES_CLEAR_DATA_LOGOUT = createAction('STORES_CLEAR_DATA_LOGOUT');

export const fetchStores = (storeId = '') => {
  return async (dispatch, getState) => {
    dispatch(STORES_FETCH_DATA_INIT());

    const userQueryOptions = {
      queries: [
        {
          'attribute': 'role',
          'operator': '==',
          'value': 'Store'
        }
      ]
    };

    const usersAsStore = await fetchCollection('users', userQueryOptions);

    if (storeId) {
      let store;
      try {
        store = await fetchDocument('stores', storeId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(STORES_FETCH_DATA_FAIL({ error }));
      }

      if (!store) {
        const errorMessage = 'Store not available';
        toastr.error('', errorMessage);
        return dispatch(STORES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const stores = getState().stores.data;
      stores.push(store);

      return dispatch(
        STORES_FETCH_DATA_SUCCESS({
          data: stores,
          usersAsStore
        })
      );
    }

    let stores;

    try {
      stores = await fetchCollection('stores');
    } catch (error) {
      return dispatch(STORES_FETCH_DATA_FAIL({ error }));
    }

    if (!stores) {
      const errorMessage = 'Stores not found';
      toastr.info('', errorMessage);
      return dispatch(STORES_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      STORES_FETCH_DATA_SUCCESS({
        data: stores,
        usersAsStore
      })
    );
  };
};

const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('stores%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`stores/${logoPath}`).delete();
};

export const deleteStore = (id) => {
  return async (dispatch, getState) => {
    dispatch(STORES_DELETE_STORE_INIT());

    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .stores.data.filter((store) => store.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteStoreTask = deleteDocument('stores', id);

    try {
      await Promise.all([deleteLogoTask, deleteStoreTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        STORES_DELETE_STORE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The store was deleted.');
    return dispatch(STORES_DELETE_STORE_SUCCESS({ id }));
  };
};

export const clearStoresDataLogout = () => {
  return (dispatch) => {
    dispatch(STORES_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (id, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${id}.${fileExtension}`;

  return storageRef.child(`stores/${fileName}`).put(file);
};

const getLogoUrl = (id, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/stores%2F${id}_200x200.${fileExtension}?alt=media`;
};

export const createStore = ({
  name,
  file,
  createdAt,
  merchantID,
  publicKey,
  privateKey,
  kennitala,
  sell,
  integratorID,
  orderExport,
  orderAPIKey,
  productsAPIKey,
  categoriesAPIKey,
  tagsAPIKey,
  alwaysInStock,
  collectionID,
  minStockQty = '',
  giftcardEmail,
  giftcardPassword,
  giftcardToken,
  ordersCompanyID = '',
  orderExportStatus,
  serviceFee = '',
  paymentFee = '',
  shippingFee
}) => {
  return async (dispatch, getState) => {
    dispatch(STORES_CREATE_STORE_INIT());

    const { locale } = getState().preferences;
    const stores = getState().stores.data;

    const existStore = stores.find((storeItem) => storeItem.name === name );
    if (existStore) {
      const errorMessage = `Store with the name "${name}" exist!`;

      toastr.error('', errorMessage);
      return dispatch(
        STORES_MODIFY_STORE_FAIL({
          error: errorMessage,
        })
      );
    }

    const storeData = {
      name,
      createdAt,
      merchantID,
      publicKey,
      privateKey,
      kennitala,
      sell,
      integratorID,
      orderExport,
      orderAPIKey,
      productsAPIKey,
      categoriesAPIKey,
      tagsAPIKey,
      alwaysInStock,
      collectionID,
      minStockQty,
      giftcardEmail,
      giftcardPassword,
      giftcardToken,
      ordersCompanyID,
      orderExportStatus,
      serviceFee,
      paymentFee,
      shippingFee
    };

    let response;
    
    try {
      response = await addDocument('stores', storeData);
      const { id } = response;

      let uploadLogoTask = null;
      let logoUrl = null;
      if (file) {
        logoUrl = getLogoUrl(id, file);
        uploadLogoTask = uploadLogo(id, file);
      }

      storeData.logoUrl = logoUrl;

      const createStoreDbTask = updateDocument('stores', id, storeData);

      await Promise.all([
        uploadLogoTask,
        createStoreDbTask
      ]);

    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        STORES_CREATE_STORE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Store created successfully');
    return dispatch(STORES_CREATE_STORE_SUCCESS({ store: {
        id: response.id,
        ...storeData
      }
    }));
  };
};

export const modifyStore = ({
  name,
  file,
  createdAt,
  merchantID,
  publicKey,
  privateKey,
  kennitala,
  integratorID,
  sell,
  orderExport,
  orderAPIKey,
  productsAPIKey,
  categoriesAPIKey,
  tagsAPIKey,
  alwaysInStock,
  collectionID,
  minStockQty = '',
  giftcardEmail,
  giftcardPassword,
  giftcardToken,
  ordersCompanyID = '',
  orderExportStatus,
  serviceFee = '',
  paymentFee = '',
  shippingFee = '',
  id
}) => {
  return async (dispatch, getState) => {
    dispatch(STORES_MODIFY_STORE_INIT());
    const { locale } = getState().preferences;
    const stores = getState().stores.data;

    const store = stores.find((thisStores) => thisStores.id === id);

    const existStore = stores.filter((storeItem) => storeItem.id !== id).find((storeItem) => storeItem.name === name );
    if (existStore) {
      const errorMessage = `Store with the name "${name}" exist!`;

      toastr.error('', errorMessage);
      return dispatch(
        STORES_MODIFY_STORE_FAIL({
          error: errorMessage,
        })
      );
    }

    const { logoUrl } = store;
    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (file) {
      newLogoUrl = getLogoUrl(id, file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
      uploadLogoTask = uploadLogo(id, file);
    }

    const storeData = {
      name,
      createdAt,
      merchantID,
      publicKey,
      privateKey,
      kennitala,
      integratorID,
      sell,
      orderExport,
      orderAPIKey,
      productsAPIKey,
      categoriesAPIKey,
      tagsAPIKey,
      logoUrl: newLogoUrl || logoUrl,
      alwaysInStock,
      minStockQty,
      collectionID,
      giftcardEmail,
      giftcardPassword,
      giftcardToken,
      ordersCompanyID,
      orderExportStatus,
      serviceFee,
      paymentFee,
      shippingFee
    };
    const updateStoreDbTask = updateDocument('stores', id, storeData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateStoreDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        STORES_MODIFY_STORE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Store updated successfully');
    return dispatch(STORES_MODIFY_STORE_SUCCESS({ store: storeData, id }));
  };
};

export const storesCleanUp = () => (dispatch) => dispatch(STORES_CLEAN_UP());