/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import moment from 'moment';
import { checkAccess } from 'permissions';
import { storesCleanUp } from 'state/actions/stores';
import { getGiftcardToken } from 'state/actions/settings';
import { useFormatMessage } from 'hooks';
import DatePicker from 'react-datepicker';
import ErrorMessage from 'components/ErrorMessage';
import { authByUid } from 'state/actions/auth';

import './StoreForm.scss';

const StoreForm = ({ isEditing, store, onSubmitHandler, schema }) => {
  const { loading, success, usersAsStore, authLoading } = useSelector(
    (state) => ({
      loading: state.stores.loading,
      success: state.stores.success,
      usersAsStore: state.stores.usersAsStore,
      authLoading: state.auth.loading,
    }),
    shallowEqual
  );

  const initialEditState = () => {
    let initialState = {};

    if(!isEditing){
      initialState = Object.keys(store).reduce(
        (result, current) => {
          return {
            ...result,
            [current]: true,
          };
        },{}
      );
    }

    return initialState;
  };

  const orderExportStatus = [
    {
      key: 'todo',
      label: 'Todo'
    },
    {
      key: 'in_picking',
      label: 'In picking'
    },
    {
      key: 'dispatched',
      label: 'Dispatched'
    },
    {
      key: 'received',
      label: 'Received by service desk'
    },
    {
      key: 'ready',
      label: 'Ready'
    }
  ];

  const [getToken, setGetToken] = useState(false);

  const [fieldsEditState, setFieldsEditState] = useState(initialEditState);

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, control, watch, setValue, setError, clearErrors } = useForm({
    defaultValues: { ...store },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(storesCleanUp());
  }, [dispatch, success, setValue]);

  let imagePreviewUrl =
    watch('file') && watch('file')[0]
      ? URL.createObjectURL(watch('file')[0])
      : store.logoUrl;

  if(imagePreviewUrl){
    const imagePreviewUrlObj = new URL(imagePreviewUrl);
    if(imagePreviewUrlObj.protocol !== 'blob:'){
      const urlSearchParams = imagePreviewUrlObj.searchParams;
      urlSearchParams.set('version', Date.now());
      imagePreviewUrlObj.search = urlSearchParams.toString();
      imagePreviewUrl = imagePreviewUrlObj.toString();
    }
  }

  let currentUsersAsStore = {};

  if(store && usersAsStore && usersAsStore.length > 0){
    currentUsersAsStore = usersAsStore.filter(item => {
      if(item.additionalData && item.additionalData.stores){
        if(item.additionalData.stores.includes(store.id)){
          return true;
        }
      }

      return false;
    });
  }

  const handleLoginAs = (e, userId) => {
    e.preventDefault();
    dispatch(authByUid(userId));
  };

  const editFieldHandler = (field) => {
    setFieldsEditState(current => ({...current, [field]: true}));
  };

  const loginAsLabel = useFormatMessage('StoreForm.loginAs');
  const pickAnotherFileMessage = useFormatMessage('StoreForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('StoreForm.pickFile');

  const infoLabel = useFormatMessage('StoreForm.storeInfo');
  const previewLabel = useFormatMessage('StoreForm.storePreview');

  const AccessToEditStore = checkAccess('edit store');
  const AccessToEditStoreCollectionID = checkAccess('edit store collectionID');

  const {giftcardEmail, giftcardPassword} = watch(["giftcardEmail", "giftcardPassword"]);

  const getGiftcardTokenHandler = async() => {
    setGetToken(true);
    clearErrors(['giftcardEmail', 'giftcardPassword']);
    let token = '';

    const validEmail = await yup.string().required().email('must be a valid email').isValid(giftcardEmail);

    if(!validEmail){
      setError('giftcardEmail', {type: "manual", message: "Error"});
    }

    if(!giftcardPassword){
      setError('giftcardPassword', {type: "manual", message: "Required Field"});
    }

    if(giftcardEmail && giftcardPassword && validEmail){
      token = await getGiftcardToken(giftcardEmail, giftcardPassword);
      if(token){
        setValue('giftcardToken', token);
      }
    }
    setGetToken(false);
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                { infoLabel }
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.name ? '' : 'readOnly'}
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("name")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                {isEditing ? (
                  <input 
                    type="hidden"
                    name="createdAt"
                    ref={register} />
                  )
                :
                  (
                    <>
                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">
                            {useFormatMessage('StoreForm.created')}
                          </label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <Controller
                              control={control}
                              name="createdAt"
                              render={({ onChange, name, value }) => (
                                <DatePicker
                                  selected={value ? new Date(value) : ''}
                                  onChange={onChange}
                                  className="input"
                                  name={name}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      {errors.createdAt && (
                        <div className="field is-horizontal">
                          <div className="field-label is-normal" />
                          <div className="field-body">
                            <ErrorMessage />
                          </div>
                        </div>
                      )}
                    </>
                  )
                }

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.kennitala')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.kennitala ? '' : 'readOnly'}
                          name="kennitala"
                          id="kennitala"
                          className={classNames('input', {
                            'is-danger': errors.kennitala,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("kennitala")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.kennitala && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.minStockQty')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.minStockQty ? '' : 'readOnly'}
                          name="minStockQty"
                          id="minStockQty"
                          className={classNames('input', {
                            'is-danger': errors.minStockQty,
                          })}
                          ref={register}
                          type="number"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("minStockQty")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.minStockQty && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal has-check">
                  <div className="field-label">
                    <label className="label">
                      {useFormatMessage('StoreForm.alwaysInStock')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="b-checkbox checkbox">
                          <input
                            type="checkbox"
                            name="alwaysInStock"
                            id="alwaysInStock"
                            ref={register}
                          />
                          <span className="check is-blue" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.alwaysInStock && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <h3 className="title is-6">{useFormatMessage('StoreForm.saltpay')}</h3>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.merchantID')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.merchantID ? '' : 'readOnly'}
                          name="merchantID"
                          id="merchantID"
                          className={classNames('input', {
                            'is-danger': errors.merchantID,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("merchantID")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.merchantID && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.publicKey')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.publicKey ? '' : 'readOnly'}
                          name="publicKey"
                          id="publicKey"
                          className={classNames('input', {
                            'is-danger': errors.publicKey,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("publicKey")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.publicKey && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.privateKey')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.privateKey ? '' : 'readOnly'}
                          name="privateKey"
                          id="privateKey"
                          className={classNames('input', {
                            'is-danger': errors.privateKey,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("privateKey")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.privateKey && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <h3 className="title is-6">{useFormatMessage('StoreForm.giftcardCredentials')}</h3>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.giftcardEmail')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.giftcardEmail ? '' : 'readOnly'}
                          name="giftcardEmail"
                          id="giftcardEmail"
                          className={classNames('input', {
                            'is-danger': errors.giftcardEmail,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("giftcardEmail")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.giftcardEmail && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.giftcardPassword')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.giftcardPassword ? '' : 'readOnly'}
                          name="giftcardPassword"
                          id="giftcardPassword"
                          className={classNames('input', {
                            'is-danger': errors.giftcardPassword,
                          })}
                          ref={register}
                          type="password"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("giftcardPassword")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.giftcardPassword && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.giftcardToken')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly='readOnly'
                          name="giftcardToken"
                          id="giftcardToken"
                          className={classNames('input', {
                            'is-danger': errors.giftcardToken,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    <span 
                      onClick={getGiftcardTokenHandler} 
                      className={`button ${
                        getToken && 'is-loading'
                      }`}
                    >
                      {useFormatMessage('StoreForm.getGiftcardToken')}
                    </span>
                  </div>
                </div>
                {errors.giftcardToken && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal has-check">
                  <div className="field-label">
                    <label className="label">
                      {useFormatMessage('StoreForm.sell')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="b-checkbox checkbox">
                          <input
                            disabled={!watch('merchantID') || !watch('publicKey') || !watch('privateKey') || !watch('giftcardToken') || !watch('giftcardPassword') || !watch('giftcardEmail')}
                            type="checkbox"
                            name="sell"
                            id="sell"
                            ref={register}
                          />
                          <span className="check is-blue" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.sell && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <h3 className="title is-6">{useFormatMessage('StoreForm.fees')}</h3>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.serviceFee')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.serviceFee ? '' : 'readOnly'}
                          name="serviceFee"
                          id="serviceFee"
                          className={classNames('input', {
                            'is-danger': errors.serviceFee,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("serviceFee")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.serviceFee && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.paymentFee')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.paymentFee ? '' : 'readOnly'}
                          name="paymentFee"
                          id="paymentFee"
                          className={classNames('input', {
                            'is-danger': errors.paymentFee,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("paymentFee")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.paymentFee && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.shippingFee')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.shippingFee ? '' : 'readOnly'}
                          name="shippingFee"
                          id="shippingFee"
                          className={classNames('input', {
                            'is-danger': errors.shippingFee,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("shippingFee")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.shippingFee && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <h3 className="title is-6">Integrator Settings</h3>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.integratorID')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.integratorID ? '' : 'readOnly'}
                          name="integratorID"
                          id="integratorID"
                          className={classNames('input', {
                            'is-danger': errors.integratorID,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("integratorID")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.integratorID && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.collectionID')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.collectionID ? '' : 'readOnly'}
                          name="collectionID"
                          id="collectionID"
                          className={classNames('input', {
                            'is-danger': errors.collectionID,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing && AccessToEditStoreCollectionID &&
                      <span 
                        onClick={() => editFieldHandler("collectionID")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                    {store.collectionID && AccessToEditStoreCollectionID &&
                      <a href={`https://www.kringlan.is/api/v1/update-search-index?apiKey=8Bvawjvaj3YR9G9kAgfkBdzdg8pVVNWUqHmTJF8yT692cgF3aYMDFJosTLijeLeF&fullSync=1&collectionId=${store.collectionID}`}
                        rel="noreferrer"
                        target="_blank"
                        className="button ml-1">
                        {useFormatMessage('StoreForm.syncProducts')}
                      </a>
                    }
                  </div>
                </div>
                {errors.collectionID && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.productsAPIKey')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.productsAPIKey ? '' : 'readOnly'}
                          name="productsAPIKey"
                          id="productsAPIKey"
                          className={classNames('input', {
                            'is-danger': errors.productsAPIKey,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("productsAPIKey")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.productsAPIKey && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.categoriesAPIKey')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.categoriesAPIKey ? '' : 'readOnly'}
                          name="categoriesAPIKey"
                          id="categoriesAPIKey"
                          className={classNames('input', {
                            'is-danger': errors.categoriesAPIKey,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("categoriesAPIKey")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.categoriesAPIKey && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.tagsAPIKey')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.tagsAPIKey ? '' : 'readOnly'}
                          name="tagsAPIKey"
                          id="tagsAPIKey"
                          className={classNames('input', {
                            'is-danger': errors.tagsAPIKey,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("tagsAPIKey")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.tagsAPIKey && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <h3 className="title is-6">{useFormatMessage('StoreForm.integratorOrdersAPI')}</h3>
                <div className="field is-horizontal has-check">
                  <div className="field-label">
                    <label className="label">
                      {useFormatMessage('StoreForm.orderExport')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="b-checkbox checkbox">
                          <input
                            disabled={!watch('orderAPIKey')}
                            type="checkbox"
                            name="orderExport"
                            id="orderExport"
                            ref={register}
                          />
                          <span className="check is-blue" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.orderExport && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.orderExportStatus')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <span className="select">
                          <select
                            name="orderExportStatus"
                            ref={register}
                          >
                            {orderExportStatus.map((status) => (
                              <option key={status.key} value={status.key}>
                                {status.label}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("orderExportStatus")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.orderExportStatus && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.orderAPIKey')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.orderAPIKey ? '' : 'readOnly'}
                          name="orderAPIKey"
                          id="orderAPIKey"
                          className={classNames('input', {
                            'is-danger': errors.orderAPIKey,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("orderAPIKey")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.orderAPIKey && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('StoreForm.ordersCompanyID')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.ordersCompanyID ? '' : 'readOnly'}
                          name="ordersCompanyID"
                          id="ordersCompanyID"
                          className={classNames('input', {
                            'is-danger': errors.ordersCompanyID,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("ordersCompanyID")} 
                        className="button">
                        {useFormatMessage('StoreForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.ordersCompanyID && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />
                { AccessToEditStore && 
                  <div className="field is-horizontal">
                    <div className="field-label" />
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                            <button
                              disabled={getToken}
                              type="submit"
                              className={`button blue-button ${
                                loading && 'is-loading'
                              }`}
                            >
                              <span>{useFormatMessage('StoreForm.submit')}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                { previewLabel }
              </p>
            </header>
            <div className="card-content">
              {imagePreviewUrl && (
                <>
                  <div className="is-store-logo image has-max-width is-aligned-center">
                    <img
                      className="store-logo"
                      src={imagePreviewUrl}
                      alt="Store logo preview"
                    />
                  </div>
                  <hr />
                </>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('StoreForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('StoreForm.created')}
                </label>
                <div className="control is-clearfix" data-testid="date">
                  <p className="date">
                    {moment(new Date(watch('createdAt'))).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>

              {AccessToEditStore && isEditing && currentUsersAsStore && (
                <>
                  <hr />
                  {currentUsersAsStore.map(item => (
                    <div key={item.id} className="field is-grouped">
                      <div className="control is-align-self-center">
                        <p>{item.name}</p>
                      </div>
                      <div className="control">
                        <button 
                          className='button'
                          disabled={authLoading}
                          onClick={event => handleLoginAs(event, item.id) }
                        >
                          <span>{ loginAsLabel }</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

StoreForm.propTypes = {
  store: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    kennitala: PropTypes.string,
    integratorID: PropTypes.string,
    merchantID: PropTypes.string,
    publicKey: PropTypes.string,
    privateKey: PropTypes.string,
    tagsAPIKey: PropTypes.string
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

export default StoreForm;
