import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { getRolesForUserList } from 'permissions';
import { firebaseError } from 'utils';
import { getAuth } from "firebase/auth";
import { getStorage, ref, deleteObject, uploadBytes } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';
import {
  fetchCollection,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocument,
  getCountDocuments
} from '../api';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
  'USERS_FETCH_DATA_SUCCESS'
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
  'USERS_DELETE_USER_SUCCESS'
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
  'USERS_CREATE_USER_SUCCESS'
);
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
  'USERS_MODIFY_USER_SUCCESS'
);
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_UPDATE_USER_PASSWORD_INIT = createAction('USERS_UPDATE_USER_PASSWORD_INIT');
export const USERS_UPDATE_USER_PASSWORD_SUCCESS = createAction(
  'USERS_UPDATE_USER_PASSWORD_SUCCESS'
);
export const USERS_UPDATE_USER_PASSWORD_FAIL = createAction('USERS_UPDATE_USER_PASSWORD_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const fetchUsers = (userId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(USERS_FETCH_DATA_INIT());

    if (userId) {
      let user;
      try {
        user = await fetchDocument('users', userId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(USERS_FETCH_DATA_FAIL({ error }));
      }

      if (!user) {
        const errorMessage = 'User not available';
        toastr.error('', errorMessage);
        return dispatch(USERS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const users = getState().users.data;
      users.push(user);

      return dispatch(
        USERS_FETCH_DATA_SUCCESS({
          data: users,
        })
      );
    }

    const { id, isAdmin, role } = getState().auth.userData;

    let users;

    const filterRoles = getRolesForUserList(isAdmin, role);

    try {
      let queryOptions = {};
      if (filterRoles.length) {
        if (!filterRoles.includes('all')) {
          queryOptions = {
            'queries': [{
              'attribute': 'role',
              'operator': 'in',
              'value': filterRoles
            }]
          };
        }
        users = await fetchCollection('users', queryOptions );
      }
    } catch (error) {
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    if (!users) {
      const errorMessage = 'Users not found';
      toastr.info('', errorMessage);
      return dispatch(USERS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        data: users.filter((user) => user.id !== id),
      })
    );
  };
};

export const getUserList = async({ limit = '', cursorId = '', direction = '', sort = null, subQueries = null } = {}) => {
  const queries = [];
  const auth = getAuth();
  
  await auth.authStateReady();
  const user = auth.currentUser;
  const userData = await fetchDocument('users', user.uid);

  const { isAdmin, role } = userData;
  const filterRoles = getRolesForUserList(isAdmin, role);

  if (filterRoles.length) {
    if (!filterRoles.includes('all')) {
      queries.push({
        attribute: 'role',
        operator: 'in',
        value: filterRoles  
      });
    }
  }

  const response = {
    data: [],
    rowCount: 0
  };

  const queryNextOptions = {
    queries,
    sort:[
      {
        'attribute': 'role',
        'order': 'asc'
      }
    ],
  };

  const queryPrevOptions = {
    queries,
    sort:[
      {
        'attribute': 'role',
        'order': 'asc'
      }
    ],
  };

  const queryCountOptions = {
    queries
  };

  const queryOptions = {
    queries,
    sort:[
      {
        'attribute': 'role',
        'order': 'asc'
      }
    ],
  };

  if (subQueries) {
    queryCountOptions.subQueries = subQueries;
    queryOptions.subQueries = subQueries;
    queryNextOptions.subQueries = subQueries;
    queryPrevOptions.subQueries = subQueries;
  }

  if (sort) {
    queryOptions.sort.push({sort});
    queryNextOptions.sort.push({sort});
    queryPrevOptions.sort.push({sort});
  }

  if (limit) {
    queryOptions.limit = limit;
    queryNextOptions.limit = limit;
    queryPrevOptions.limit = limit;
  }

  if (cursorId && direction) {
    queryOptions.direction = direction;
    queryOptions.cursorId = cursorId;
  }

  try{
    if (filterRoles && filterRoles.length) {
      const count = await getCountDocuments('users', queryCountOptions);
      response.rowCount = count;

      const users = await fetchCollection('users', queryOptions);
      response.data = users;

      if (users && users.length && limit) {
        queryNextOptions.direction = 'next';
        queryNextOptions.cursorId = users[users.length - 1].id;

        const usersNextX2 = await fetchCollection('users', queryNextOptions);
        if (usersNextX2 && (usersNextX2.length === limit)) {
          response.cursorIdNextX2 = usersNextX2[limit - 1].id;
        }

        queryPrevOptions.direction = 'prev';
        queryPrevOptions.cursorId = users[0].id;

        const usersPrevX2 = await fetchCollection('users', queryPrevOptions);
        if (usersPrevX2 && (usersPrevX2.length === limit)) {
          response.cursorIdPrevX2 = usersPrevX2[0].id;
        }
      }
    }
  }
  catch (error){
    console.log(error);
    toastr.error('', error);
  }

  return response;
};

const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('users%2F').pop().split('?alt=media').shift();
  const storage = getStorage();
  const desertRef = ref(storage, `users/${logoPath}`);
  
  return deleteObject(desertRef);
};

export const removeUser = async(id) => {
  const user = await fetchDocument('users', id);

  const { logoUrl } = user;

  const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

  const deleteUserTask = deleteDocument('users', id);

  try {
    await Promise.all([deleteLogoTask, deleteUserTask]);
  } catch (error) {
    const errorMessage = firebaseError(error.code, 'is');
    toastr.error('', errorMessage);
  }

  toastr.success('', 'The user was deleted.');
};


export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch(USERS_DELETE_USER_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .users.data.filter((user) => user.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteUserTask = deleteDocument('users', id);

    try {
      await Promise.all([deleteLogoTask, deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_DELETE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The user was deleted.');
    return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
  };
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (uid, file) => {
  const fileExtension = file.name.split('.').pop();
  const fileName = `${uid}.${fileExtension}`;

  const storage = getStorage();
  const storageRef = ref(storage, `users/${fileName}`);

  return uploadBytes(storageRef, file);
};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/users%2F${uid}_200x200.${fileExtension}?alt=media`;
};

export const createUser = ({
  name,
  email,
  password,
  location,
  file,
  createdAt,
  isAdmin = false,
  role,
  additionalData = {}
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_CREATE_USER_INIT());
    const { locale } = getState().preferences;

    const updateRole = isAdmin ? 'Admin' : role;

    let response;
    try {
      const firebaseApiFunctions = getFunctions();
      const createUserAuth = httpsCallable(firebaseApiFunctions, 'httpsCreateUser');

      response = await createUserAuth({ email, isAdmin, role: updateRole, password });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    const { uid } = response.data;

    let uploadLogoTask = null;
    let logoUrl = null;
    if (file) {
      logoUrl = getLogoUrl(uid, file);
      uploadLogoTask = uploadLogo(uid, file);
    }
    const userData = { name, email, location, logoUrl, createdAt, isAdmin, role: updateRole, additionalData };

    const createUserDbTask = createDocument('users', uid, userData);

    try {
      await Promise.all([
        uploadLogoTask,
        createUserDbTask
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'User created successfully');
    return dispatch(USERS_CREATE_USER_SUCCESS({ user: response.data }));
  };
};

export const modifyUser = ({
  name,
  location,
  isAdmin = false,
  file,
  createdAt,
  role,
  id,
  isEditing,
  isProfile,
  additionalData = {}
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;
    const users = getState().users.data;

    const user = isProfile
      ? getState().auth.userData
      : users.find((thisUser) => thisUser.id === id);


    if (role === 'Store') {
      const existStore = users.filter((storeItem) => storeItem.id !== id).find((storeUser) => storeUser.name === name && storeUser.role === 'Store' );
      if (existStore) {
        const errorMessage = `Store with the name "${name}" exist!`;

        toastr.error('', errorMessage);
        return dispatch(
          USERS_MODIFY_USER_FAIL({
            error: errorMessage,
          })
        );
      }
    }

    const { logoUrl = '' } = user;
    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (file) {
      newLogoUrl = getLogoUrl(id, file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
      uploadLogoTask = uploadLogo(id, file);
    }

    const updateRole = isAdmin ? 'Admin' : role;

    const userData = {
      name,
      location,
      createdAt,
      role: updateRole,
      additionalData,
      isAdmin,
      logoUrl: newLogoUrl || logoUrl,
    };
    const updateUserDbTask = updateDocument('users', id, userData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    const { uid } = getAuth().currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_USER_DATA({ ...userData, id }));
    }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'User updated successfully');
    }

    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: userData, id }));
  };
};

export const updateUserPass = ({
  id,
  password
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_UPDATE_USER_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      const firebaseApiFunctions = getFunctions();
      const updateUserPassword = httpsCallable(firebaseApiFunctions, 'httpsUpdateUserPassword');

      await updateUserPassword({ id, password });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_UPDATE_USER_PASSWORD_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'User password updated successfully');

    return dispatch(USERS_UPDATE_USER_PASSWORD_SUCCESS());
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());